<template>
    <div>
        <ATabs
            v-model:activeKey="state.activeKey"
            @change="handleTabChange">
            <ATabPane
                key="1"
                tab="Master Cluster">
            </ATabPane>
            <ATabPane
                key="2"
                tab="Konfigurasi Cluster">
            </ATabPane>
            <ATabPane
                key="3"
                tab="Periode Cluster">
            </ATabPane>
        </ATabs>

        <div>
            <router-view />
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
    components: {
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const state = reactive({
            activeKey: '1',
            tabs: [
                {
                    key: 1,
                    route: '/master/cluster/master',
                },
                {
                    key: 2,
                    route: '/master/cluster/configuration',
                },
                {
                    key: 3,
                    route: '/master/cluster/periode',
                },
            ],
        })

        const handleTabChange = (key) => {
            const selectedTab = state.tabs.find(tab => tab.key === parseInt(key))
            if (selectedTab) {
                router.push(selectedTab.route)
            }
        }

        const setActiveTabBasedOnRoute = () => {
            const currentTab = state.tabs.find(tab => tab.route === route.path)
            state.activeKey = currentTab ? currentTab.key.toString() : '' // Handle case if the route doesn't match any tab
        }

        onMounted(() => {
            setActiveTabBasedOnRoute()
        })

        watch(() => route.path, () => {
            setActiveTabBasedOnRoute()
        })



        return {
            state,
            handleTabChange,
        }
    },
})
</script>
